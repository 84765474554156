import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import Table from '@@components/Table/Table';
import TableRow from '@@components/TableRow/TableRow';
import { TLocale } from '@@config/locale';
import { TReportTypeCode } from '@@config/reportTypeCode';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getReportCheckJSONURL, mergeData } from '@@utils/utils';

type TTableItemData = {
    LABEL: string;
    TOOLTIP?: string;
    VALUE: string;
    KIND: 'data' | 'image';
};

type TTableItem = {
    [key: string]: TTableItemData;
};

type TLabelCategory = {
    SECTION_TITLE: string;
    ITEMS: TTableItem[];
};

type TContent = {
    LABEL_CATEGORIES: TLabelCategory[];
};

type TGradingReportProps = {
    readonly data: {
        REPORT_TYPE_CODE: TReportTypeCode;
        [key: string]: string;
    };
};

const fetchReportCheckJsonContent = async (reportType: TReportTypeCode, locale: TLocale): Promise<TContent> => {
    const url: URL = getReportCheckJSONURL(reportType, locale);
    const request = new Request(url, { method: 'GET' });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch Report Check Template JSON');
    }

    const json = await response.json();
    return json;
};

const GradingReport: React.FC<TGradingReportProps> = ({ data }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const reportTypeCode: TReportTypeCode = data['REPORT_TYPE_CODE'];
    const clarityCharacteristics: string = data['CLARITY_CHARACTERISTICS'] ?? '';

    const {
        data: content,
        isLoading,
        error,
    } = useQuery<TContent>({
        queryKey: ['reportCheckJson', reportTypeCode, locale],
        queryFn: () => fetchReportCheckJsonContent(reportTypeCode, locale),
        staleTime: 5 * 60 * 1000, // 5 minutes
        select: jsonData => mergeData(jsonData, data),
    });

    if (isLoading) {
        return <div className='skeleton w-100 mr-md-3' style={{ height: '65vh' }}></div>;
    }

    if (error) {
        console.error(error);
        return <div>Error loading report content</div>;
    }

    if (content) {
        return (
            <div className='row'>
                <div className='col-12'>
                    {content['LABEL_CATEGORIES'].map((category: TLabelCategory) => (
                        <Table key={category['SECTION_TITLE']} title={category['SECTION_TITLE']}>
                            {category['ITEMS'].map((item: TTableItem) => {
                                const key = Object.keys(item)[0]!;

                                if (key === 'PLOTIMG') {
                                    const tableItemData: TTableItemData = item[key]!;
                                    return (
                                        <TableRow
                                            key={key}
                                            tableItemKey={key}
                                            tableItemData={tableItemData}
                                            clarityChars={clarityCharacteristics}
                                        />
                                    );
                                }

                                if (key) {
                                    const tableItemData: TTableItemData = item[key]!;
                                    return <TableRow key={key} tableItemKey={key} tableItemData={tableItemData} />;
                                }
                                return null;
                            })}
                        </Table>
                    ))}
                </div>
            </div>
        );
    }

    return <div className='skeleton w-100' style={{ height: '65vh' }}></div>;
};

export type { TTableItemData, TTableItem, TLabelCategory, TContent };
export default GradingReport;
