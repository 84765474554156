import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from '@@components/ErrorPage/ErrorPage';
import Landing from '@@components/Landing/Landing';
import Results from '@@components/Results/Results';
import { reactResultsLoader } from '@@components/Results/Results.loader';
import Root from '@@components/Root/Root';
import { LocaleProvider } from '@@providers/LocaleProvider';
import '@@/styles/scss/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
        },
    },
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/report-check-landing',
                element: <Landing />,
            },
            {
                path: '/report-check',
                element: <Results />,
                loader: reactResultsLoader,
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <LocaleProvider>
                <RouterProvider router={router} />
            </LocaleProvider>
            <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
    </React.StrictMode>
);
