
import React, { useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh-cn';
import { TGSTPropertyLabels } from '@@config/i18n';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getLocaleDayJS } from '@@utils/utils';

type TReportBasicInfoProps = {
    readonly reportNumber: string | number;
    readonly reportDate: string;
    readonly i18n: TGSTPropertyLabels;
};

const ReportBasicInfo: React.FC<TReportBasicInfoProps> = ({ reportNumber, reportDate, i18n }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const reportDateFormatted = dayjs(reportDate).locale(getLocaleDayJS(locale)).format('MMM. D, YYYY');
    /** Translations pulled from the i18n JSON object that is received from GIA.edu endpoint */
    const gia_www_i18n_giareportnum: string = i18n['gia_www_i18n_giareportnum'] ?? 'GIA Report Number';
    const gia_www_i18n_dateofissue: string = i18n['gia_www_i18n_dateofissue'] ?? 'Date of Issue';

    return (
        <div className='report-basic-info d-flex mb-2 mb-lg-0'>
            <div className='d-flex flex-column'>
                <h3 className='m-0 p-0 uppercase'>{gia_www_i18n_giareportnum}</h3>
                <p id='REPORT_NO' className='mt-1 mt-md-2'>
                    {reportNumber}
                </p>
            </div>
            <div className='d-flex flex-column'>
                <h3 className='m-0 p-0 uppercase text-right text-md-left'>{gia_www_i18n_dateofissue}</h3>
                <p id='REPORT_DT' className='mt-1 mt-md-2 text-right text-md-left'>
                    {reportDateFormatted}
                </p>
            </div>
        </div>
    );
};

export default ReportBasicInfo;
export type { TReportBasicInfoProps };
