import React from 'react';
import { getBaseURL } from '@@utils/utils';

type ClarityCharacteristicProps = {
    readonly char: string;
};

const ClarityCharacteristic: React.FC<ClarityCharacteristicProps> = ({ char }) => {
    return (
        <div className='d-flex justify-content-start align-items-center'>
            <img
                width={15}
                height={15}
                src={`${getBaseURL()}/partials/report-check/img/InclusionImages/${char.replace(/\s/g, "")}.jpg`}
                alt={char}
            />
            <span>{char}</span>
        </div>
    );
};

export default ClarityCharacteristic;
