// import dayjs from 'dayjs';
import React from 'react';
import parse from 'html-react-parser';
import { Tab, Tabs } from 'react-bootstrap';
import { useLoaderData } from 'react-router-dom';
import AdditionalInformation from '@@components/AdditionalInformation/AdditionalInformation.tsx';
import DataError, { TErrorCode } from '@@components/DataError/DataError.tsx';
import Fancybox from '@@components/Fancybox/Fancybox.tsx';
import GradingReport from '@@components/GradingReport/GradingReport.tsx';
import ImageLoader from '@@components/ImageLoader/ImageLoader.tsx';
import JewelryGradingReport from '@@components/JewelryGradingReport/JewelryGradingReport.tsx';
import LabGrownBanner from '@@components/LabGrownBanner/LabGrownBanner.tsx';
import ReportBasicInfo from '@@components/ReportBasicInfo/ReportBasicInfo.tsx';
import ReportSupplemental from '@@components/ReportSupplemental/ReportSupplemental.tsx';
import ReportSupplementals from '@@components/ReportSupplementals/ReportSupplementals.tsx';
import RightRail from '@@components/RightRail/RightRail.tsx';
import SupplementalsAccordion from '@@components/SupplementalsAccordion/SupplementalsAccordion.tsx';
import { TReportTypeCode } from '@@config/reportTypeCode';
import { getElectronicReportURL, parseLabels } from '@@utils/utils.ts';

type LoaderData = {
    data?: any;
    error?: { code: TErrorCode; message: string };
};

const Results: React.FC = () => {
    const { data, error } = useLoaderData() as LoaderData;

    if (error) return <DataError errorCode={error.code} fallbackMessage={error.message} />;

    const { labels, i18n }: ReturnType<typeof parseLabels> = parseLabels(data);

    const REPORT_TYPE_CODE: TReportTypeCode = data.REPORT_TYPE_CODE;
    const REPORT_NO: string = data.REPORT_NO;
    const REPORT_DT: string = data.REPORT_DT;
    const PDF_URL: string = data.PDF_URL;
    const WEIGHT: string = data.WEIGHT;
    const CUT_GRADE: string = data?.['CUT_GRADE'] ?? '';
    const hasCutGrade: boolean = CUT_GRADE !== '';

    /**
     * The primary download URL is determined by the report type code and whether or not a PDF is available.
     */
    let primaryCTA: URL | null = null;
    if (REPORT_TYPE_CODE === 'DER') {
        // For Diamond eReport we have to curate a custom URL based on the report number and weight.
        primaryCTA = getElectronicReportURL(REPORT_NO, WEIGHT);
    } else if (PDF_URL) {
        // Default for most reports is the PDF_URL.
        primaryCTA = new URL(PDF_URL);
    }

    // Boolean currently only for determining whether to display the subtitle "FOR NATURAL DIAMOND" below report type.
    const isNaturalDiamond = ['DD', 'CI', 'CD', 'FC', 'DER', 'DG', 'DOR', 'CDOR'].includes(REPORT_TYPE_CODE);

    // Boolean for whether to display the full-width Lab-Grown Banner above report info.
    const isLabGrown: boolean = ['LGDOSS', 'LGDG', 'LGCD', 'LGCI', 'LGDR'].includes(REPORT_TYPE_CODE);

    const isJewelryLayout: boolean = ['JG'].includes(REPORT_TYPE_CODE);

    const isDefaultLayout: boolean = [
        'DG',
        'SDG',
        'DER',
        'DD',
        'CI',
        'SCI',
        'EO',
        'ER',
        'ID',
        'RG',
        'TR',
        'CCR',
        'JR',
        'SPOR',
        'SPR',
        'AL',
        'CD',
        'FC',
        'SCD',
        'TOR',
        'PI',
        'NPI',
        'PG',
        'RO',
        'SO',
        'MLE',
        'DOR',
        'LGDOSS',
        'LGDG',
        'CDOR',
        'LGCD',
        'LGCI',
        'SG',
        'LGDR',
    ].includes(REPORT_TYPE_CODE);

    const showTabs: boolean = data?.['TREATMENTS_DATA']?.length > 0 || data?.['GROWTH_METHOD_DATA']?.length > 0;

    if (isJewelryLayout) {
        return (
            <main id='main-container' className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}>
                <section className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-start'>
                    <ReportBasicInfo reportNumber={REPORT_NO} reportDate={REPORT_DT} i18n={i18n} />

                    <div className='d-none d-lg-block'>
                        <ReportSupplementals
                            reportTypeCode={REPORT_TYPE_CODE}
                            reportNumber={REPORT_NO}
                            primaryCTA={primaryCTA}
                            labels={labels}
                            i18n={i18n}
                        />
                    </div>
                </section>

                <hr className='dotted-double mt-sm-1 mt-md-3' />

                {/* Main Report Sleeve */}
                <JewelryGradingReport data={data} labels={labels} />

                <AdditionalInformation reportTypeCode={REPORT_TYPE_CODE} />
            </main>
        );
    }

    if (isDefaultLayout) {
        return (
            <main id='main-container' className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}>
                <section className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-start'>
                    <ReportBasicInfo reportNumber={REPORT_NO} reportDate={REPORT_DT} i18n={i18n} />

                    <div className='d-none d-lg-block'>
                        <ReportSupplemental
                            reportTypeCode={REPORT_TYPE_CODE}
                            reportNumber={REPORT_NO}
                            primaryCTA={primaryCTA}
                            reportDAC={data.DIGITAL_CARD}
                            reportDTL={data.DTL_PDF_URL}
                            reportDTLP={data.DTLP_PDF_URL}
                            reportDTTL={data.DTTL_PDF_URL}
                            i18n={i18n}
                        />
                    </div>
                </section>
                <hr className='dotted-double mt-sm-1 mt-md-3' />
                <div className='row report-details-and-sidebar no-gutters'>
                    <div className='col-12'>
                        {isLabGrown && <LabGrownBanner reportTypeCode={REPORT_TYPE_CODE} i18n={i18n} />}
                    </div>
                    <div className='col-sm-12 col-md-9'>
                        <div className='row mt-3 mb-5'>
                            <div className='col'>
                                <h1 id='REPORT_TYPE' className='text-left bg-none letter-spacing-normal pb-0 mb-0'>
                                    {parse(data?.['REPORT_TYPE'])}
                                </h1>
                                {isNaturalDiamond && (
                                    <span id='REPORT_TYPE_SUBTITLE' className='text-uppercase font-weight-bold'>
                                        FOR NATURAL DIAMOND
                                    </span>
                                )}
                            </div>
                            {data?.['DGTIMG'] && (
                                <div className='col-sm-12 col-md-3' style={{ margin: 'auto 18px' }}>
                                    <Fancybox>
                                        <a href={data['DGTIMG']} data-fancybox>
                                            <ImageLoader
                                                className='img-fluid'
                                                src={data['DGTIMG']}
                                                minHeight={125}
                                                alt='...'
                                            />
                                        </a>
                                    </Fancybox>
                                </div>
                            )}
                        </div>

                        {!showTabs && <GradingReport data={data} />}

                        {showTabs && (
                            <Tabs
                                defaultActiveKey='report-details'
                                className='supp-tabs d-flex justify-content-center text-uppercase my-3 border-0'
                            >
                                <Tab eventKey='report-details' title='Report Details'>
                                    <GradingReport data={data} />
                                </Tab>

                                {data?.['TREATMENTS_DATA'] && (
                                    <Tab eventKey='treatment' title='Treatment'>
                                        <SupplementalsAccordion supplementalContent={data['TREATMENTS_DATA']} />
                                    </Tab>
                                )}
                            </Tabs>
                        )}
                    </div>
                    <aside id='rightRail' className='col-sm-12 col-md-3'>
                        <RightRail reportTypeCode={REPORT_TYPE_CODE} hasCutGrade={hasCutGrade} />
                    </aside>
                </div>
            </main>
        );
    }

    return <main className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}></main>;
};

export default Results;
