import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import InfoCard from '@@components/InfoCard/InfoCard.tsx';
import { TLocale } from '@@config/locale/';
import { TReportTypeCode } from '@@config/reportTypeCode/';
import { TCard, TAdditionalInformation } from '@@config/wcs/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getBaseURL } from '@@utils/utils';

const fetchAdditionalInformationData = async (locale: TLocale, reportTypeCode: TReportTypeCode): Promise<TAdditionalInformation> => {
    const url: URL = new URL(
        `${getBaseURL()}/sites/Satellite?pagename=GIA/AliasModules/JSON&locale=${locale}&metatitle=Additional-Info-Diamonds&reportTypeCode=${reportTypeCode}`
    );
    const request = new Request(url, { method: 'GET' });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch header content');
    }
    return response.json();
}

type TAdditionalInformationProps = {
    readonly reportTypeCode: TReportTypeCode;
};

const AdditionalInformation: React.FC<TAdditionalInformationProps> = ({ reportTypeCode }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    const {
        data,
        isPending,
        error,
    } = useQuery({
        queryKey: ['additionalInformation', locale],
        queryFn: () => fetchAdditionalInformationData(locale, reportTypeCode),
        staleTime: 60 * 60 * 1000, // 1 hour
    })

    if (error) {
        console.error(error);
        return <div className='d-none'>Error loading additional information content</div>;
    }    

    return (
        <div className='container-lg my-4 my-lg-0'>
            {!isPending ? (
                <>
                    {data?.ALIAS_MODULES && (
                        <div className='row row-cols-1 row-cols-sm-3'>
                            {data?.ALIAS_MODULES.map((module: TCard) => {
                                return <InfoCard data={module} key={module.ASSET_ID.CID} />;
                            })}
                        </div>
                    )}
                </>
            ) : (
                <div className='skeleton' style={{minHeight: '10rem'}}></div>
            )}
        </div>
    );
};

export default AdditionalInformation;
