import React from 'react';
import { Fancybox } from '@fancyapps/ui';
import FullScreenButton from '@@components/FullScreenButton/FullScreenButton.tsx';
import ImageLoader from '@@components/ImageLoader/ImageLoader.tsx';
import Slider, { Settings } from 'react-slick';

type TFancyGallerySlide = {
    src: string;
};

type TSlide = {
    image: {
        src: string;
        alt: string;
    };
};

type TSimpleSliderProps = {
    readonly slides: TSlide[];
};

const settings: Settings = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                arrows: false,
            },
        },
    ],
};

const SimpleSlider: React.FC<TSimpleSliderProps> = ({ slides }) => {
    function createFancyGallery() {
        const fancyGallerySlides: TFancyGallerySlide[] = slides.map((content: TSlide) => {
            return { src: content.image.src, caption: content.image.alt };
        });

        new Fancybox(fancyGallerySlides, { hideScrollbar: true });
    }

    return (
        <div className='simple-slider'>
            <Slider {...settings}>
                {slides.map((slide: TSlide, index: number) => (
                    <div key={index}>
                        <ImageLoader
                            className='img-fluid'
                            src={slide.image.src}
                            alt={slide.image.alt}
                            minHeight={340}
                        />
                    </div>
                ))}
            </Slider>
            <FullScreenButton onClick={createFancyGallery} />
        </div>
    );
};

export type { TSlide };
export default SimpleSlider;
