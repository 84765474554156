import React from 'react';
import Fancybox from '@@components/Fancybox/Fancybox';
import { TLocale } from '@@config/locale/';
import { getBaseURL } from '@@utils/utils';

type GradingScaleProps = {
    locale: TLocale;
    hasCutGrade: boolean;
};

const localeAbbreviations: Record<TLocale, string> = {
    en_US: 'EN',
    ja_JP: 'JP',
    zh_CN: 'CN',
};

const GradingScale: React.FC<GradingScaleProps> = ({ locale, hasCutGrade }) => {
    const imgSrc = `${getBaseURL()}/img/reportcheck/2012_0309_GIA_4Cs_Scales_Black_Vert_${hasCutGrade ? '' : 'NoCut_'}${
        localeAbbreviations[locale]
    }.jpg`;

    return (
        <Fancybox>
            <a href={imgSrc} data-fancybox>
                <img className='img-fluid px-3' src={imgSrc} alt='Grading Scale' />
            </a>
        </Fancybox>
    );
};

export default GradingScale;
