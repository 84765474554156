import { json, LoaderFunction } from 'react-router-dom';
import { TLocale } from '@@config/locale';
import { getAWSEnv, getCookie, getRdwbApiHost } from '@@utils/utils'; // Adjust import path as needed

export const reactResultsLoader: LoaderFunction = async ({ request }): Promise<Response> => {
    const url: URL = new URL(request.url);
    const reportno = url.searchParams.get('reportno');
    const locale = url.searchParams.get('locale') as TLocale;
    const AWS_ENV = getAWSEnv();
    const RDWB_API_HOST = getRdwbApiHost();

    const rdwbToken = await getCookie('rdwb-token');
    if (!rdwbToken && AWS_ENV !== 'local') {
        console.debug('[RDWB] Token expired or not found');        
        window.location.href = `/report-check-landing${reportno ? `?reportno=${reportno}` : ''}`;
        window.location.reload();

        return json({ error: { code: '403', message: 'Unauthorized' } });
    }
    const rdwbTokenEncoded = rdwbToken.replace('/*', '%2f*');
    const rdwbEndpoint: URL = new URL(`${RDWB_API_HOST}?reportno=${reportno}&locale=${locale}&env=${AWS_ENV}&USEREG=1`);

    const headers = new Headers({
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'Rdwb-Token': rdwbTokenEncoded,
    });

    const rdwbRequest = new Request(rdwbEndpoint.toString(), {
        method: 'GET',
        headers: headers,
        cache: 'no-cache',
    });

    try {
        const response: Response = await fetch(rdwbRequest);
        console.debug('[RDWB] response?.ok: ', response?.ok);

        if (!response.ok) {
            throw new Error(`HTTP Error >> Status: ${response.status}`);
        }

        const data: any = await response.json();
        console.debug('[RDWB] data: ', data);

        if ('ERROR_CODE' in data) {
            return json({ error: { code: data.ERROR_CODE, message: 'An error occurred' } });
        }

        return json({ data });
    } catch (error) {
        console.error('Loader error:', error);
        return json({ error: { code: '500', message: 'Internal server error' } });
    }
};
