import React from 'react';
import { Accordion } from 'react-bootstrap';
import { TAssetId } from '@@config/wcs';

type TBodyItem = {
    TITLE: string;
    BODY: string[];
    IMAGE: Record<string, unknown>;
    MEDIA: Record<string, unknown>;
};

type TSuppContent = {
    ASSET_ID: TAssetId;
    TITLE: string;
    BODY_HTML: string;
    BODY_JSON: TBodyItem[];
};

type TSupplementalsAccordionProps = {
    supplementalContent: TSuppContent[];
};

const SupplementalsAccordion: React.FC<TSupplementalsAccordionProps> = ({ supplementalContent }) => {
    return (
        <Accordion>
            {supplementalContent.map((content, index) => (
                <Accordion.Item eventKey={`${index}`} key={`${content?.['ASSET_ID'].CID}`}>
                    <Accordion.Header as='h5' className='w-100'>
                        {content.TITLE}
                    </Accordion.Header>
                    <Accordion.Body className='px-5 py-3'>
                        {content?.['BODY_JSON'].map(item => {
                            return Object.entries(item).map(([key, value]) => {
                                if (key === 'TITLE' && typeof value === 'string') {
                                    return <h3 key={key}>{value}</h3>;
                                }

                                if (key === 'BODY' && Array.isArray(value)) {
                                    return value.map((paragraph, j) => <p key={`${key}-${j}`}>{paragraph}</p>);
                                }

                                if (key === 'IMAGE' && typeof value === 'object' && value !== null && 'SRC' in value) {
                                    const imageSource = value['SRC'] as string;
                                    const imageAlt = value['ALT'] as string;

                                    return (
                                        <img
                                            key={key}
                                            className='img-fluid'
                                            src={`https://www.gia.edu/${imageSource}`}
                                            alt={imageAlt}
                                            style={{ marginBottom: '1rem' }}
                                        />
                                    );
                                }

                                return null;
                            });
                        })}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export type { TSupplementalsAccordionProps };
export default SupplementalsAccordion;
