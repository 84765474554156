import React from 'react';
import TableHeader from '@@components/TableHeader/TableHeader.tsx';

type TableProps = {
    title: string;
    children: React.ReactNode;
};

const Table: React.FC<TableProps> = ({ title, children }) => {
    return (
        <table className='table table-striped mb-4'>
            <TableHeader title={title} />
            <tbody>
                {children}
            </tbody>
        </table>
    );
};

export default Table;