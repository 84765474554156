import React, { useState, useEffect, useRef } from 'react';

type ImageLoaderProps = {
    src: string;
    alt: string;
    className?: string;
    width?: string | number;
    height?: string | number;
    minHeight?: string | number;
};

const ImageLoader: React.FC<ImageLoaderProps> = ({
    src,
    alt,
    className = '',
    width = '100%',
    height = 'auto',
    minHeight = '200px',
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);

    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setIsLoading(true);
        setHasError(false);

        const img = new Image();
        img.src = src;

        const handleLoad = () => {
            setIsLoading(false);
        };

        const handleError = () => {
            setIsLoading(false);
            setHasError(true);
        };

        img.addEventListener('load', handleLoad);
        img.addEventListener('error', handleError);

        return () => {
            img.removeEventListener('load', handleLoad);
            img.removeEventListener('error', handleError);
        };
    }, [src]);

    useEffect(() => {
        if (imgRef.current?.complete) {
            setIsLoading(false);
        }
    }, []);

    return (
        <div
            style={{
                width,
                height: isLoading ? minHeight : height,
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 500ms ease-in-out',
            }}
            className={`${isLoading ? 'skeleton' : ''}`}
        >
            <img
                ref={imgRef}
                className={className}
                src={src}
                alt={alt}
                style={{
                    opacity: isLoading || hasError ? 0 : 1,
                    transition: 'opacity 500ms ease-in-out',
                    objectFit: 'cover',
                }}
            />
        </div>
    );
};

export default ImageLoader;